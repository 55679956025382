import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _31ba0afa = () => interopDefault(import('../pages/check-email.vue' /* webpackChunkName: "pages/check-email" */))
const _0b8b770c = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _9a171a12 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _1a9b9aaa = () => interopDefault(import('../pages/medications/index.vue' /* webpackChunkName: "pages/medications/index" */))
const _1c3ff336 = () => interopDefault(import('../pages/password-updated.vue' /* webpackChunkName: "pages/password-updated" */))
const _28052e7e = () => interopDefault(import('../pages/paylinks/index.vue' /* webpackChunkName: "pages/paylinks/index" */))
const _6d617dbf = () => interopDefault(import('../pages/prescriptions/index.vue' /* webpackChunkName: "pages/prescriptions/index" */))
const _3903b650 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _63ac4d6f = () => interopDefault(import('../pages/registered.vue' /* webpackChunkName: "pages/registered" */))
const _f2be542c = () => interopDefault(import('../pages/resend-confirmation.vue' /* webpackChunkName: "pages/resend-confirmation" */))
const _7d16e246 = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _82b0f860 = () => interopDefault(import('../pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _5290ecd9 = () => interopDefault(import('../pages/settings/close-account.vue' /* webpackChunkName: "pages/settings/close-account" */))
const _9e9104fa = () => interopDefault(import('../pages/settings/export.vue' /* webpackChunkName: "pages/settings/export" */))
const _e93eac06 = () => interopDefault(import('../pages/settings/hospitals/index.vue' /* webpackChunkName: "pages/settings/hospitals/index" */))
const _5d3ba042 = () => interopDefault(import('../pages/settings/options.vue' /* webpackChunkName: "pages/settings/options" */))
const _6e7bf460 = () => interopDefault(import('../pages/settings/pharmacies.vue' /* webpackChunkName: "pages/settings/pharmacies" */))
const _0304c94f = () => interopDefault(import('../pages/settings/security.vue' /* webpackChunkName: "pages/settings/security" */))
const _087653da = () => interopDefault(import('../pages/settings/user.vue' /* webpackChunkName: "pages/settings/user" */))
const _ec5e46ea = () => interopDefault(import('../pages/settings/hospitals/new.vue' /* webpackChunkName: "pages/settings/hospitals/new" */))
const _a4ab0330 = () => interopDefault(import('../pages/settings/hospitals/_id/index.vue' /* webpackChunkName: "pages/settings/hospitals/_id/index" */))
const _669437e4 = () => interopDefault(import('../pages/settings/hospitals/_id/edit.vue' /* webpackChunkName: "pages/settings/hospitals/_id/edit" */))
const _23e6e869 = () => interopDefault(import('../pages/embed/rx.vue' /* webpackChunkName: "pages/embed/rx" */))
const _6217b8cd = () => interopDefault(import('../pages/prescriptions/new.vue' /* webpackChunkName: "pages/prescriptions/new" */))
const _ef4a9dcc = () => interopDefault(import('../pages/redirect/new.vue' /* webpackChunkName: "pages/redirect/new" */))
const _41e3d3f5 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _2b72710e = () => interopDefault(import('../pages/paylinks/_slug.vue' /* webpackChunkName: "pages/paylinks/_slug" */))
const _682119c8 = () => interopDefault(import('../pages/prescriptions/_signature.vue' /* webpackChunkName: "pages/prescriptions/_signature" */))
const _78e15704 = () => interopDefault(import('../pages/new-password/_uid/_token.vue' /* webpackChunkName: "pages/new-password/_uid/_token" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/check-email",
    component: _31ba0afa,
    name: "check-email"
  }, {
    path: "/login",
    component: _0b8b770c,
    name: "login"
  }, {
    path: "/logout",
    component: _9a171a12,
    name: "logout"
  }, {
    path: "/medications",
    component: _1a9b9aaa,
    name: "medications"
  }, {
    path: "/password-updated",
    component: _1c3ff336,
    name: "password-updated"
  }, {
    path: "/paylinks",
    component: _28052e7e,
    name: "paylinks"
  }, {
    path: "/prescriptions",
    component: _6d617dbf,
    name: "prescriptions"
  }, {
    path: "/register",
    component: _3903b650,
    name: "register"
  }, {
    path: "/registered",
    component: _63ac4d6f,
    name: "registered"
  }, {
    path: "/resend-confirmation",
    component: _f2be542c,
    name: "resend-confirmation"
  }, {
    path: "/reset-password",
    component: _7d16e246,
    name: "reset-password"
  }, {
    path: "/settings",
    component: _82b0f860,
    name: "settings",
    children: [{
      path: "close-account",
      component: _5290ecd9,
      name: "settings-close-account"
    }, {
      path: "export",
      component: _9e9104fa,
      name: "settings-export"
    }, {
      path: "hospitals",
      component: _e93eac06,
      name: "settings-hospitals"
    }, {
      path: "options",
      component: _5d3ba042,
      name: "settings-options"
    }, {
      path: "pharmacies",
      component: _6e7bf460,
      name: "settings-pharmacies"
    }, {
      path: "security",
      component: _0304c94f,
      name: "settings-security"
    }, {
      path: "user",
      component: _087653da,
      name: "settings-user"
    }, {
      path: "hospitals/new",
      component: _ec5e46ea,
      name: "settings-hospitals-new"
    }, {
      path: "hospitals/:id",
      component: _a4ab0330,
      name: "settings-hospitals-id"
    }, {
      path: "hospitals/:id/edit",
      component: _669437e4,
      name: "settings-hospitals-id-edit"
    }]
  }, {
    path: "/embed/rx",
    component: _23e6e869,
    name: "embed-rx"
  }, {
    path: "/prescriptions/new",
    component: _6217b8cd,
    name: "prescriptions-new"
  }, {
    path: "/redirect/new",
    component: _ef4a9dcc,
    name: "redirect-new"
  }, {
    path: "/",
    component: _41e3d3f5,
    name: "index"
  }, {
    path: "/paylinks/:slug",
    component: _2b72710e,
    name: "paylinks-slug"
  }, {
    path: "/prescriptions/:signature",
    component: _682119c8,
    name: "prescriptions-signature"
  }, {
    path: "/new-password/:uid?/:token?",
    component: _78e15704,
    name: "new-password-uid-token"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
